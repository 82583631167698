<script lang="ts" setup>
import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { PROFILE_SLUGS } from "~/utils/constants"

const userStore = useUserStore()
const authToken = useStatefulCookie("authToken")
const { user } = storeToRefs(userStore)
const route = useRoute()

const myAccountArea = computed(
  () => route.fullPath.includes(PROFILE_SLUGS.ROOT) ?? false
)

onMounted(async () => {
  if (!myAccountArea.value) {
    const checkSession = await useCheckSessionUser()
    if (authToken.value)
      await userStore.updateNewsletterSubscription(authToken.value)
    if (typeof checkSession === "object" && !user.value?.email)
      userStore.updateUserData(checkSession)
  }
})
</script>

<template>
  <div>
    <slot />
  </div>
</template>
